/*jslint browser: true*/
/*global $, jQuery*/

(function ($) {

  "use strict";

  function setSitempaHeight(){
    var fullHeight = $(document).height();
    var sitemapY = $(".sitemap").offset().top;
		var socialY = $(".bottomMenu").offset().top;
		var maxHeight = socialY - sitemapY;
    // maxHeight -= 20; // for beaty-reasons a little distance would be nice, here 20 Pixels
    $(".sitemap").css("max-height",maxHeight);
  }

  $(document).ready(function () {

    // Offcanvas main menu functions
    function off_canvas_open() {
      // set sitemap-height so that it fits into the viewport
      $('body').addClass('offcanvas-open');
      setSitempaHeight();
    }

    function off_canvas_close() {
      $('body').removeClass('offcanvas-open');
      $('.offcanvas-sidebar ul ul').removeClass('offcanvas-child-visible');
      $('.offcanvas-sidebar').delay(400).animate({scrollTop: 0}, 50);
    }

    // Offcanvas child menu functions
    function off_canvas_child_open() {
      $('.offcanvas-sidebar').addClass('offcanvas-child-open');
    }

    function off_canvas_child_close() {
      $('.offcanvas-sidebar').removeClass('offcanvas-child-open');
    }

    $('.menu-icon').click(function () {
      if (!$('body').hasClass('offcanvas-open')) {
        off_canvas_open();
      } else {
        off_canvas_close();
      }
    });
    $('.offcanvas-overlay').click(function () {
      off_canvas_close();
      off_canvas_child_close();
    });
    $(window).resize(function () {
      off_canvas_close();
      off_canvas_child_close();
    });

    $(".offcanvas-sidebar a").click(function (){
      off_canvas_close();
    });


    $('.offcanvas-child-link').click(function () {
      $(this).siblings('ul').addClass('offcanvas-child-visible');
      off_canvas_child_open();
    });
    $('.offcanvas-child-back-link').click(function () {
      $(this).parents('ul').removeClass('offcanvas-child-visible');
      off_canvas_child_close();
    });
  });

}(jQuery));
