function isBelowLogo(anElement) {
    var $logo = $(".logo-navigation"),
        $element = $(anElement),
        logoTopBorderPosition = $logo.offset().top,
        logoBottomBorderPosition = logoTopBorderPosition + $logo.height(),
        elementTopBorderPosition = $element.offset().top,
        elementBottomBorderPosition = elementTopBorderPosition + $element.find("img").height();
    return (elementTopBorderPosition <= logoBottomBorderPosition) && (elementBottomBorderPosition > logoTopBorderPosition);

}

function highlightLogoUrl(anUrl) {
    $(".logo-navigation a").not(".logoItself").each(function () {
        $(this).toggleClass("opacityHalf", ($(this).attr("href") != anUrl));
    });
}

function setProjectCategory(theCategory) {
    var currentCategory = $(".dynamicCategory").text();
    if (theCategory != currentCategory) {
        $(".dynamicCategory").fadeOut(125, function () {
            $(this).text(theCategory).fadeIn(125);
        });
    }
}

function setProjectTitle(theTitle, url) {
    var currentTitle = $(".dynamicTitle").html();
    if (url && url !== "") {
        theTitle = '<a class="noLinkStyle inherit-color" href="' + url + '">' + theTitle + '</a>';
    }
    if (theTitle != currentTitle) {
        $(".dynamicTitle").fadeOut(125, function () {
            $(this).html(theTitle).fadeIn(125);
        });
    }
}

$(function () {
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
        $("body").addClass("iosBody");
    }

    if ($(".jsTriggerJScrollOnMainSection").length) {
        // $(".main-section").jscroll({
		$(".oao-main").jscroll({
            debug: true,
            nextSelector: '.jsNextPage:last a',
            loadingHtml: '<div class="text-center mdText">… loading …</div>'

        });

    }

    if ($(".jsHomeHighligher").length) {
        $(window).scroll(function (e) {
            var foundOne = false;
            $(".jsHomeHighligher a").each(function (index) {
                if (isBelowLogo(this)) {
                    highlightLogoUrl($(this).attr("href"));
                    foundOne = true;
                }
            });
            if (!foundOne) {
                highlightLogoUrl($(".jsHomeHighligher a").first().attr("href"));
            }
        });
    }

    function checkForTitleRelevantImages() {
        var category = "", title = "",url="";
        $(".jsTitleSetter div.img_wrapper").each(function (index) {
            if (isBelowLogo(this)) {
                title = $(this).data("section-title");
                url = $(this).find('a').attr("href");
                category = $(this).parents(".jsTitleSetter").eq(0).data("projectcategory");
            }
        });
        setProjectTitle(title, url);
        setProjectCategory(category);
    }

    if ($(".jsTitleSetter div.img_wrapper").length) {
        checkForTitleRelevantImages();
        $(window).scroll(checkForTitleRelevantImages);
    }

    $('.jsLinkContainer').each(function () {
        var $that = $(this);
        $that.parents('div').eq(1).css('cursor', 'pointer').addClass('clickable').click(function () {
            location.href = $that.attr('href');
        });
    });

    $('.jsLinkRow').each(function () {
        var $that = $(this);
        $that.parents('tr').eq(0).css('cursor', 'pointer').addClass('clickable').click(function () {
            location.href = $that.attr('href');
        });
    });
    if ($.tablesorter) {
        $('.jsTableSort').tablesorter();
    }

    //$('.logo-navigation .logo').css('cursor', 'pointer');
    //$('body').prepend('<div style="position:fixed;width:40px;z-index:100000;left:2px;top:2px;color:magenta;"><div class="visible-xs">XS</div><div class="visible-sm">SM</div><div class="visible-md">MD</div><div class="visible-lg">LG</div></div>');


    $('.carousel').carousel({
        interval: false
    });


    // lightbox
    if ($.fancybox) {
		var baseUrl = document.body.getAttribute('data-oao-base-url');
        $(".jsOpenHtmlLayer a").fancybox({
            type: "iframe",
            padding: 0,
            margin: 0,
            autosize: true,
            width: "100%",
            iframe: {scrolling: 'no', preload: false},
            height: "100%",
            minHeight: "100%",
            minWidth: "100%",
            autoCenter: false,
            fitToView: false,
            scrolling: 'no',
            modal: false,
            nextSpeed: 750,
            prevSpeed: 750,
            openEffect: 'fade',
            closeEffect: 'fade',
            nextEffect: 'fade',
            prevEffect: 'fade',
            openOpacity: false,
            tpl: {
                closeBtn: '<a title="Close" class="fancybox-item fancybox-close " href="javascript:;"><img src="'+baseUrl+'/assets/images/assets/close_black.svg" alt="schließen"></a>',
                next: '<a class="fancybox-nav fancybox-next"><span></span></a>',
                prev: '<a class="fancybox-nav fancybox-prev"><span></span></a>'
            }

        });
        $(".jsOpenHtmlLayerDark a").fancybox({
            type: "iframe",
            padding: 0,
            margin: 0,
            autosize: true,
            width: "100%",
            iframe: {scrolling: 'no', preload: false},
            height: "100%",
            minHeight: "100%",
            minWidth: "100%",
            autoCenter: false,
            fitToView: false,
            scrolling: 'no',
            modal: false,
            nextSpeed: 750,
            prevSpeed: 750,
            openEffect: 'fade',
            closeEffect: 'fade',
            nextEffect: 'fade',
            prevEffect: 'fade',
            openOpacity: false,
            tpl: {
                closeBtn: '<a title="Close" class="fancybox-item fancybox-close fancybox-close-white" href="javascript:;"><img src="'+baseUrl+'/assets/images/assets/close_white.svg" alt="schließen"></a>',
                next: '<a class="fancybox-nav fancybox-next"><span></span></a>',
                prev: '<a class="fancybox-nav fancybox-prev"><span></span></a>'

            }

        });
        $(".jsOpenHtmlLayer img").click(function (e) {
            $(this).parents(".jsOpenHtmlLayer").eq(0).find("a").trigger("click");
        });
        $(".jsOpenHtmlLayerDark img").click(function (e) {
            $(this).parents(".jsOpenHtmlLayerDark").eq(0).find("a").trigger("click");
        });
    }
    //$(".jsOpenHtmlLayer").css('cursor', 'pointer').fancybox( {type: 'iframe', });

    $('a.jsOpenInNewWindow').click(function () {
        var win = window.open($(this).attr("href"));
        win.focus();
        return false;
    });

});
